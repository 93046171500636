@media (min-width: 767px) {
  /* Feedback Button CSS */
  #feedbackify .fby-tab-r.fby-tab {
    top: 100%;
    transform: rotate(90deg) translateY(50%) translateX(-35px);
  }
  .mt {
    margin-top: 25px;
  }
  .mt-50 {
    margin-top: 50px;
  }
  .details-popup {
    .mat-dialog-container {
      width: 966px;
      background-color: #f5f5f5;
      padding: 20px;
    }
  }
  /* End */
}

@media (max-width: 1399px) {
  .steps-heading {
    font-size: 35px;
    line-height: 80px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1280px) {
  .mb-85 {
    margin-bottom: 55px;
  }
}

@media (max-width: 992px) {
  .steps-heading {
    margin-top: 92px;
    margin-bottom: 45px;
    line-height: 50px;
    font-size: 40px;
  }
  .step-form-wrapper {
    padding: 24px 100px;
  }
  .mt {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .edit-slider-wrapper {
    right: 30px;
  }
}

@media (max-width: 767px) {
  .steps-heading {
    font-size: 32px;
    margin-top: 40px;
    margin-bottom: 30px;
    line-height: 38px;
  }
  .cdk-overlay-container .cdk-overlay-pane {
    max-width: 90vw !important;
  }
  .step-form-wrapper {
    padding: 24px 15px;
    margin-bottom: 30px;
  }
  .mb-85 {
    margin-bottom: 35px;
  }
  .mb-20 {
    margin-bottom: 15px;
  }
  .wt-outline-btn {
    padding-left: 27px;
    padding-right: 27px;
  }
  .c-checkbox-toggle {
    span {
      font-size: 13px;
    }
  }
  .side-pannel {
    width: 100%;
  }
  .edit-slider-wrapper {
    right: 0;
  }
  div .mat-dialog-container {
    padding: 24px 29px;
  }
  .close-btn {
    top: 2px;
    right: 5px;
  }
}

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* Global styles */

body {
  font-family: "Roboto", sans-serif;
}

button,
select,
input {
  &:focus {
    outline: none;
  }
}

.steps-heading {
  text-align: center;
  font-size: 50px;
  line-height: 80px;
  margin-bottom: 115px;
  margin-top: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 3px 6px #0000004d;
}

.step-form-wrapper {
  border-radius: 10px;
  background-color: #fff;
  max-width: 780px;
  padding: 24px 160px;
  margin: 0 auto;
}
div .mat-dialog-container {
  padding: 24px 39px;
}

@media (max-width: 767px) {
  .modal-container {
    padding: 21px 10px;
    z-index: 1;
  }
}

.info-label {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
}

.round-input,
.round-select {
  font-size: 16px;
  line-height: 20px;
  background-color: #fff;
  border: 1px solid #646464;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  transition: border-color 0.3s;
  padding: 0 15px;
  color: #393939;
  &:focus {
    outline: none;
    border-color: #f37321;
    box-shadow: none;
  }
}

.round-select {
  background: transparent url(/assets/images/down-arrow.svg) no-repeat
    calc(100% - 14px) 14px;
}
.details-popup {
  .mat-dialog-container {
    background-color: #f5f5f5;
  }

  .card {
    border: none;
    padding: 10px 20px;
    font-size: small;
    .head {
      text-align: center;

      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      margin-bottom: 6px;
    }
    .val {
      text-align: center;

      font-size: 16px;
      line-height: 18px;

      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      margin-bottom: 3px;
    }
    .center {
      text-align: center;
      margin: 12px auto;
    }

    p {
      font-size: inherit;
    }
    .c-checkbox-toggle span {
      font-size: 10px;
      vertical-align: middle;
    }
    .middle {
      font-size: 12px;
      font-weight: normal;
      line-height: 13px;
    }
    .bill {
      .middle {
        line-height: 16px;
      }
    }
    .middle {
      position: relative;
      float: left;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .card-2 {
    border: none;
    padding: 20px 20px;
    font-size: small;
    background-color: #fff;
    border-radius: 8px;
    .head {
      text-align: center;

      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      margin-bottom: 6px;
    }
    .val {
      text-align: center;

      font-size: 16px;
      line-height: 16px;

      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      margin-bottom: 3px;
    }
    .center {
      text-align: center;
      margin: 12px auto;
    }

    p {
      font-size: inherit;
    }
    .c-checkbox-toggle span {
      font-size: 10px;
      vertical-align: middle;
    }
    .middle {
      font-size: 12px;
      font-weight: normal;
      line-height: 13px;
    }
    .bill {
      .middle {
        line-height: 16px;
      }
    }
    .middle {
      position: relative;
      float: left;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .tc {
      font-size: small;
      font-weight: 600;
      color: #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(50%, -50%);
      right: -21px;
    }
    .ct {
      font-size: small;
      font-weight: 600;
      color: #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(50%, -50%);
      right: -26px;
    }
  }
}

.outline-btn {
  font-size: 16px;
  line-height: 22px;
  color: #f37321;
  border: 1px solid #f37321;
  padding: 8px 31px;
  background-color: transparent;
  border-radius: 20px;
  cursor: pointer;
  &.gray {
    border-color: #646464;
    color: #646464;
  }
}

.wt-outline-btn {
  background-color: #f37321;
  color: #fff;
  border: 1px solid #ffffff;
  border-radius: 20px;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 37px;
  cursor: pointer;
  transition: background-color 0.3s;
  &.orange {
    border-color: #f37321;
  }
  &:hover,
  &:focus {
    background-color: #d97a30;
    border-color: #d97a30;
  }
}

.disabled {
  background-color: #fbceb2;
  border-color: #fbceb2;
  color: #fff;
  border: 1px solid #ffffff;
  border-radius: 20px;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 37px;
  cursor: pointer;
  transition: background-color 0.3s;
  &.orange {
    background-color: #fbceb2;
    border-color: #fbceb2;
  }
  &:hover,
  &:focus {
    background-color: #fbceb2;
  }
}

.c-slider {
  width: 100%;
  .mat-slider-thumb {
    box-shadow: 0px 3px 6px #00000069;
    background: #fff;
    width: 26px;
    height: 26px;
    bottom: -12px;
  }
  &.mat-accent .mat-slider-thumb-label,
  &.mat-accent .mat-slider-track-fill {
    background-color: #d97a30;
  }
  &.mat-slider-horizontal .mat-slider-track-wrapper,
  &.mat-slider-horizontal .mat-slider-track-background,
  &.mat-slider-horizontal .mat-slider-track-fill {
    height: 3px;
  }
}

.c-tooltip.mat-tooltip {
  font-size: 14px;
  line-height: 19px;
  color: #393939;
  padding: 14px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px #00000029;
  overflow: visible;
  &.w-max-200 {
    max-width: 200px;
  }
  &.w-max-322 {
    max-width: 322px;
  }
  &.w-max-172 {
    max-width: 172px;
  }
  &.w-max-258 {
    max-width: 258px;
  }
  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 8px;
    margin-left: -8px;
  }
  &:before {
    border-color: rgba(238, 238, 238, 0);
    border-top-color: #eee;
    border-width: 9px;
    margin-left: -9px;
  }
}
.n-tooltip.mat-tooltip {
  font-size: 14px;
  line-height: 19px;
  color: #393939;
  padding: 14px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px #00000029;
  overflow: visible;
  &.w-max-200 {
    max-width: 200px;
  }
  &.w-max-322 {
    max-width: 322px;
  }
  &.w-max-172 {
    max-width: 172px;
  }
  &.w-max-258 {
    max-width: 258px;
  }
  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  // &:after {
  //   border-color: rgba(255, 255, 255, 0);
  //   border-top-color: #fff;
  //   border-width: 8px;
  //   margin-left: -8px;
  // }
  // &:before {
  //   border-color: rgba(238, 238, 238, 0);
  //   border-top-color: #eee;
  //   border-width: 9px;
  //   margin-left: -9px;
  // }
}
.b-tooltip.mat-tooltip {
  font-size: 14px;
  line-height: 17px;
  color: #000;
  padding: 14px;
  border-radius: 10px;
  background-color: #ecfdea;
  box-shadow: 0px 0px 10px #00000029;
  border: 1px solid #a2d87e;
  overflow: visible;
  &.w-max-200 {
    max-width: 200px;
  }
  &.w-max-322 {
    max-width: 322px;
  }
  &.w-max-172 {
    max-width: 172px;
  }
  &.w-max-258 {
    max-width: 258px;
  }
  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ecfdea;
    border-width: 8px;
    margin-left: -8px;
  }
  &:before {
    border-color: rgba(238, 238, 238, 0);
    border-top-color: #a2d87e;
    border-width: 9px;
    margin-left: -9px;
  }
}

/* Form Styles */
.c-form {
  .mat-form-field {
    width: 100%;
  }
  .mat-form-field-label {
    span {
      color: #de5246;
    }
  }
  .mat-form-field-appearance-outline
    .mat-form-field-outline-thick
    .mat-form-field-outline-start,
  .mat-form-field-appearance-outline
    .mat-form-field-outline-thick
    .mat-form-field-outline-end,
  .mat-form-field-appearance-outline
    .mat-form-field-outline-thick
    .mat-form-field-outline-gap,
  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-end,
  .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-width: 0;
  }
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 18px;
  }
  .mat-form-field-should-float.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      border-color: #f37321;
    }
    &.ng-invalid,
    &.mat-form-field-invalid {
      .mat-form-field-wrapper {
        border-color: #f44336;
      }
    }
  }
  .mat-form-field-infix {
    padding: 0;
    height: 34px;
  }
  input.mat-input-element,
  .mat-form-field-label {
    font-size: 14px;
    line-height: 19px;
  }
  input.mat-input-element {
    position: relative;
    top: -2px;
  }
  .mat-form-field-label mat-label {
    background-color: #fff;
    position: relative;
    top: -2px;
  }
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1em) scale(0.65);
  }
  .mat-form-field-label-wrapper {
    padding-top: 0;
    height: 34px;
    top: -1.44375em;
    overflow: visible;
  }
  .mat-form-field.mat-focused .mat-form-field-label,
  .mat-form-field.mat-form-field-should-float .mat-form-field-label {
    color: #f37321;
  }
  .mat-form-field.mat-form-field-should-float.mat-form-field-invalid
    .mat-form-field-label,
  .mat-form-field.mat-form-field-should-float.ng-invalid .mat-form-field-label {
    color: #f44336;
  }
  .mat-form-field-appearance-outline .mat-form-field-prefix {
    font-size: 14px;
    top: -3px;
    position: relative;
  }
  .wt-outline-btn {
    font-size: 14px;
    padding: 5px 37px 3px 37px;
    margin-top: 30px;
    width: 100%;
    margin-bottom: 20px;
  }
  .mat-radio-label {
    font-size: 12px;
    line-height: 16px;
  }
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #f37321;
  }
  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #f37321;
  }
  .mat-radio-container {
    align-self: flex-start;
    margin-top: 2px;
  }
  .mat-select-arrow {
    border: 0;
    width: 12px;
    height: 8px;
    background: transparent url("assets/images/dropdown-arrow.svg") no-repeat
      center;
    margin-top: 5px;
  }
  .mat-select-value {
    font-size: 14px;
    top: -3px;
    position: relative;
  }
}

.validation-wrapper {
  position: relative;
}
.invalid-data {
  color: red;
  font-size: 11px;
  font-weight: 400;
  position: relative;
  margin-top: -15px;
  margin-bottom: 10px;
  padding-left: 13px;
}

.sml-label {
  font-size: 12px;
  font-weight: 600;
  padding-left: 15px;
  color: #333;
}

.close-btn {
  position: absolute;
  top: 1px;
  right: 2px;
  border: 0;
  background: 0;
  cursor: pointer;
  padding: 10px;
  z-index: 2;
}

.form-info {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 12px;
}

.c-radio-label {
  font-size: 12px;
  line-height: 16px;
  max-width: calc(100% - 40px);
  display: inline-block;
  vertical-align: top;
  a {
    color: #4f89f4;
    text-decoration: none;
  }
}
a {
  text-decoration: none;
}

.mat-dialog-container {
  position: relative;
}

/* End Form Styles */

.mb-85 {
  margin-bottom: 85px;
}
.mb-55 {
  margin-bottom: 55px;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.ml-auto {
  margin-left: auto;
}

.error {
  color: orangered;
}

.position-relative {
  position: relative;
}

.close-button {
  position: relative;
  float: right;
  background: none;
  border: none;
  right: -30px;
  top: -30px;
  cursor: pointer;
  color: #f37321;
}

/* CSS Loading Animation */
.page-loading {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* End CSS Loading Animation */
.grey {
  color: gray;
}
.modal-container {
  padding: 21px 36px;
  max-width: 464px;
  position: relative;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden;
}

.m-title {
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  letter-spacing: 0.13px;
  color: #393939;
  margin-bottom: 19px;
}

@media (max-width: 767px) {
  .modal-container {
    padding: 21px 0;
    z-index: 1;
  }
}

/* Hide number field arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* Custom Notification Styles */
.mat-snack-bar-container {
  .mat-simple-snackbar {
    font-size: 16px;
  }
  .mat-simple-snackbar-action {
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  &.c-success-notif,
  &.c-error-notif,
  &.c-warning-notif {
    color: #fff;
  }
  &.c-success-notif {
    background-color: #51a351;
  }
  &.c-error-notif {
    background-color: #bd362f;
  }
  &.c-warning-notif {
    background-color: #f89406;
  }
}
/* End Custom Notification Styles */

/* New Design Radio Group */
.c-radio-l {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #000000;
}
.c-radio-wrap {
  span {
    display: inline-block;
    border: 1px solid #f0f0f0;
    border-radius: 12px 0 0 12px;
    font-size: 14px;
    line-height: 22 px;
    color: #8e8e8e;
    padding: 0 8px;
    min-width: 129px;
    cursor: pointer;
    border-right-width: 0;
  }
  input {
    position: absolute;
    opacity: 0;
    &:checked + span {
      background-color: #fff3eb;
      border-color: #fff3eb;
      color: #f37321;
      font-weight: 500;
    }
  }
  label:last-child {
    span {
      border-radius: 0 12px 12px 0px;
      border-right-width: 1px;
      border-left-width: 0;
    }
  }
}
/* End New Design Radio Group */

/* New Design Checkbox for SEG */
.c-checkbox-toggle {
  span {
    font-size: 14px;
    line-height: 20px;
    color: #8e8e8e;
    vertical-align: middle;
  }
  label {
    vertical-align: middle;
  }
  input {
    position: absolute;
    opacity: 0;
  }
  .active {
    color: #121212;
  }
  .c-check-toggle {
    width: 24px;
    height: 12px;
    position: relative;
    border: 1px solid #4f89f4;
    border-radius: 7px;
    margin: 0 7px;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background-color: #4f89f4;
      border-radius: 50%;
      position: absolute;
      top: 1px;
      left: 12px;
      transition: transform 0.2s;
    }
  }
  input:checked + .c-check-toggle {
    &:before {
      transform: translateX(-11px);
    }
  }
}
/* End New Design Checkbox for SEG */

/* Side Pannel */
.side-pannel {
  background-color: #fff;
  width: 360px;
  height: 100vh;
  padding: 26px;
  transform: translateX(-100%);
  transition: transform 0.3s;
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 10;
  &.open {
    transform: translateX(0);
  }
  .side-pannel-header {
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    color: #000000;
    a {
      color: #4f89f4;
      text-decoration: none;
    }
  }
  .side-pannel-heading {
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin-top: 25px;
    margin-bottom: 15px;
    font-weight: 500;
  }
}
.side-pannel-shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 5;
}
.side-pannel-close {
  position: absolute;
  background: transparent;
  border: 0;
  top: 17px;
  right: 17px;
}
/* End Side Pannel */

/* Edit Slide Component */
.edit-slider-wrapper {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.34);
  border: 1px solid #dbdbdb;
  padding: 18px 18px 0 18px;
  position: absolute;
  bottom: 8px;
  right: 50px;
  width: 325px;
  border-radius: 10px;
  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(245, 245, 245, 0);
    border-bottom-color: #f5f5f5;
    border-width: 10px;
    margin-left: -10px;
  }
  &:before {
    border-color: rgba(219, 219, 219, 0);
    border-bottom-color: #dbdbdb;
    border-width: 11px;
    margin-left: -11px;
  }
}

.unit-box {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  font-size: 24px;
  line-height: 18px;
  letter-spacing: 0px;
  color: #000000;
  display: inline-block;
  padding: 5px;
  margin-right: 10px;
}

.units-txt {
  font-size: 24px;
  line-height: 18px;
  color: #000000;
  display: inline-block;
}

.unit-done {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 19px;
  color: #4f89f4;
  cursor: pointer;
  margin-left: 16px;
}
/* End Edit Slide Component */
.br-captcha {
  border: 1px solid #eee;
}

.reload-catcha-btn {
  position: absolute;
  background: #fff;
  border: 0;
  padding: 5px;
  top: -10px;
  left: 0;
  line-height: 16px;
}
.mat-tooltip {
  white-space: pre-line;
}
